import { RouteComponentProps } from '@reach/router';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import { staticStyles } from 'styles';

import { Container } from '../components/Container';

const PrivacyPolicyPage: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Helmet
        title={`Wienerberger - ${formatMessage({
          id: 'privacy-policy.title',
        })}`}
      />
      <Container className={staticStyles.staticWrapper}>
        <h1 css={staticStyles.title}>
          <FormattedMessage id="privacy-policy.title" />
        </h1>
        <p css={staticStyles.text}>
          <FormattedMessage id="privacy-policy.description" />
        </p>
        <ol>
          <li css={staticStyles.privacyPolicyText}>
            <p css={staticStyles.privacyPolicySubtitle}>
              <FormattedMessage id="privacy-policy.list.privacy-policy-scope.title" />
            </p>
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.privacy-policy-scope.paragraph.1" />
            </p>
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.privacy-policy-scope.paragraph.2" />
            </p>
          </li>
          <li>
            <FormattedMessage id="privacy-policy.list.data-collect.title" />
            <ol>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.1.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.1.2" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.2" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.2.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.2.2" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.3" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.3.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.3.2" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.3.3" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.3.4" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.4" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.4.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.4.2" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.4.3" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.4.4" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.4.5" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.5" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.2" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.3" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.4" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.5" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.6" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.5.7" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.6" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.6.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.6.2" />
                </p>
              </li>
              <li css={staticStyles.privacyPolicyText}>
                <p css={staticStyles.privacyPolicySubtitle}>
                  <FormattedMessage id="privacy-policy.list.data-collect.subtitle.7" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.7.1" />
                </p>
                <p css={staticStyles.text}>
                  <FormattedMessage id="privacy-policy.list.data-collect.paragraph.7.2" />
                </p>
              </li>
            </ol>
          </li>
          <li>
            <FormattedMessage id="privacy-policy.list.usage-of-personal-data.title" />
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.usage-of-personal-data.subtitle" />
            </p>
            <ol>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.1" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.2" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.3" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.4" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.5" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.6" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.7" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.8" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.9" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.10" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.usage-of-personal-data.paragraph.1.11" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="privacy-policy.list.legal-basis.title" />
            <ol>
              <li>
                <FormattedMessage id="privacy-policy.list.legal-basis.paragraph.1.1" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.legal-basis.paragraph.1.2" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.legal-basis.paragraph.1.3" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.legal-basis.paragraph.1.4" />
              </li>
            </ol>
          </li>

          <li>
            <FormattedMessage id="privacy-policy.list.personal-data-transfer.title" />
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.personal-data-transfer.subtitle" />
            </p>
            <ol>
              <li>
                <FormattedMessage id="privacy-policy.list.personal-data-transfer.paragraph.1.1" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.personal-data-transfer.paragraph.1.2" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.personal-data-transfer.paragraph.1.3" />
              </li>
              <li>
                <FormattedMessage id="privacy-policy.list.personal-data-transfer.paragraph.1.4" />
              </li>
            </ol>
          </li>

          <li css={staticStyles.privacyPolicyText}>
            <FormattedMessage id="privacy-policy.list.your-rights.title" />
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.your-rights.paragraph.1" />
            </p>
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.your-rights.paragraph.2" />
            </p>
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.your-rights.paragraph.3" />
            </p>
          </li>

          <li css={staticStyles.privacyPolicyText}>
            <FormattedMessage id="privacy-policy.list.data-security.title" />
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.data-security.paragraph.1" />
            </p>
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.data-security.paragraph.2" />
            </p>
          </li>

          <li>
            <FormattedMessage id="privacy-policy.list.contact.subtitle" />{' '}
            <p css={staticStyles.text}>
              <FormattedMessage id="privacy-policy.list.contact.paragraph" />
            </p>
          </li>
        </ol>
        <p css={staticStyles.text}>
          <FormattedMessage id="privacy-policy.contact.name" />
        </p>
        <p css={staticStyles.noPaddingText}>
          <FormattedMessage id="privacy-policy.contact.address" />
        </p>
        <p css={staticStyles.noPaddingText}>
          <FormattedMessage id="privacy-policy.contact.country" />
        </p>
        <p css={staticStyles.noPaddingText}>
          <FormattedMessage id="privacy-policy.contact.phone" />
        </p>

        <p css={staticStyles.noPaddingText}>
          <FormattedMessage id="privacy-policy.contact.email" />
        </p>
      </Container>
    </>
  );
};

export default PrivacyPolicyPage;
